import store from '@/store';
import {checkPermissions, checkForApps, checkForInstitutions, checkForCollections, checkSystemPermission} from '@/utils/permission';

export default {
  inserted(el, binding, vnode) {
    const { value } = binding;

    if (value) {
      var hasPermission = false;
      hasPermission = checkPermissions(value);

      if (!hasPermission) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error(`Permissions are required! Example: v-permission="['manage user','manage permission']"`);
    }
  },
};